import React from "react";
import { isEmpty } from "lodash";
import { Layout } from "../../../../src/components";
import SingleProduct from "../../components/single-product";
import "./style.scss";

const SingleProductPage = (props) => {
  const {
    product,
    product: { name, link, seo },
  } = props.pageContext;

  return (
    <Layout pageTitle={name} seo={seo}>
      <div className="layout product-layout">
        {!isEmpty(props.pageContext) ? <SingleProduct product={product} /> : <div>Something went wrong</div>}
      </div>
    </Layout>
  );
};
export default SingleProductPage;
