import React, { useState } from "react";
import AddToCartButton from "../cart/add-to-cart-button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isEmpty } from "lodash";
import SocialShareCard from "../social-share-card";
import ProductCarousel from "../product-carousel";
import { sanitize, displayPrice } from "../../utils/functions";

const productImagePlaceholder = "https://via.placeholder.com/434";

const SingleProduct = (props) => {
  const { product } = props;
  const hasImagesSizes = !isEmpty(product.image) && !isEmpty(product.image.mediaDetails.sizes);
  const imgSrcUrl = hasImagesSizes ? product.image.sourceUrl : "";

  const initialState = {
    quantity: 1,
    price: displayPrice(product),
    productId: product.databaseId,
    variationId: '',
    name: '',
  };

  const [input, setInput] = useState(initialState);

  const handleOnChange = (event) => {
    //console.log('event:',event.target.type)
    const name = event.target.name;
    const type = event.target.type;
    const value = type === 'number' ? parseFloat(event.target.value) : event.target.value;
    let newState = { ...input, [name]: value };
    if (product.nodeType === "VariableProduct" && event.target.name.includes("variable_")) {
      updateVariation(newState);
    } else {
      setInput(newState);
    }
  };

  const displayProductImages = () => {
    if (!isEmpty(product.galleryImages.nodes)) {
      return <ProductCarousel galleryImages={product.galleryImages} />;
    } else if (!isEmpty(product.image)) {
      return (
        <figure>
          <LazyLoadImage
            alt={product.image.altText ? product.image.altText : ""}
            src={imgSrcUrl} // use normal <img> attributes as props
            effect="blur"
          />
        </figure>
      );
    } else if (!isEmpty(productImagePlaceholder)) {
      return (
        <figure>
          <LazyLoadImage alt="default" height="450" src={productImagePlaceholder} width="450" effect="blur" />
        </figure>
      );
    } else {
      return null;
    }
  };

  let variationsArray = {};

  if (product.nodeType === "VariableProduct") {
    //Construct variations
    product.variations.nodes.map((variation) => {
      //console.log("variation:", variation.attributes.nodes);
      variation.attributes.nodes.map((attribute) => {
        if (variationsArray[attribute.name]) {
          if (!variationsArray[attribute.name].values.find((item) => item.attributeId === attribute.attributeId)) {
            variationsArray[attribute.name].values.push({
              value: attribute.value,
              attributeId: attribute.attributeId,
              label: attribute.label,
            });
          }
        } else {
          variationsArray[attribute.name] = {
            label: attribute.label,
            values: [
              {
                value: attribute.value,
                attributeId: attribute.attributeId,
                label: attribute.label,
              },
            ],
          };
        }
      });
    });
    //console.log("productVariations:", variationsArray);
  }

  const updateVariation = (newState) => {
    const variationIds = selectedOptionIds(newState);
    //console.log("selectedOptionIds:", variationIds);
    //console.log("Product test:", getProductFromVariationIds(variationIds));
    const variation = getProductFromVariationIds(variationIds);
    //console.log('variation:,',variation)
    const databaseId = variation[0]?.databaseId;
    if ( databaseId ) newState.variationId = databaseId;

    const attributes = variation[0]?.attributes.nodes;

    const price = variation[0]?.regularPrice;
    if ( price ) newState.price = price;

    const name = variation[0]?.name;
    if ( name ) newState.name = name;
    //console.log('attributes:',attributes)
    const variationFormat = getAttributeForVariation(attributes);
    if ( variationFormat ) newState.variation = variationFormat;
    //console.log('variationFormat:',variationFormat);

    //console.log(' newArray:', newState)

    const newVariationState = newState;
    setInput(newVariationState);
  };

  const selectedOptionIds = (inputState) => {
    let newArray = [];
    Object.keys(inputState).map((item) => {
      if (item.includes("variable_")) {
        newArray.push(inputState[item]);
      }
    });
    return newArray;
  };

  const getAttributeForVariation = (variation) => {
    let newArray = [];
    variation?.map((item) => {
      newArray.push({
        attributeName: item.name,
        attributeValue: item.value,
      });
    });
    return newArray;
  };

  const getProductFromVariationIds = (variationIdArray) => {
    return product.variations.nodes.filter((variation) =>
      variation.attributes.nodes.every((attr) => variationIdArray.includes(attr.attributeId.toString())),
    );
  };

  //console.log("test:", getProductFromVariationIds(['111', '121']));
  //console.log("input:", input);
  //console.log("product:", product);
  return (
    // @TODO Need to handle Group products differently.
    !isEmpty(product) && "GroupProduct" !== product.nodeType ? (
      <div className="single-product-page">
        <div className="row">
          <div className="product-image-wrap">
            <div className="product-image">{displayProductImages()}</div>
          </div>
          <div className="single-product-desc">
            <h1>{input.name ? input.name : product.name}</h1>
            {!isEmpty(product.description) ? (
              <p dangerouslySetInnerHTML={{ __html: sanitize(product.description) }} />
            ) : null}
            <div className="single-product-add-to-cart">
              <form>
                <h4>{input.price}</h4>
                <div>
                  <label>Qty</label>
                  <input type="number" value={input.quantity} name="quantity" onChange={handleOnChange} />
                </div>
                {product.nodeType === "VariableProduct" && (
                  <div className="options">
                    {Object.keys(variationsArray).map((group, index) => (
                      <div key={index}>
                        <label>{variationsArray[group].label}</label>
                        <select name={`variable_${group}`} onChange={handleOnChange} required>
                          <option value="">{variationsArray[group].label}</option>
                          {variationsArray[group].values.map((variation, index) => (
                            <option key={index} value={variation.attributeId}>
                              {variation.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                )}
                <AddToCartButton product={product} input={input} />
              </form>
            </div>
            <SocialShareCard title={product.name} sectionTitle="Share this product" link={product.uri} />
          </div>
        </div>
      </div>
    ) : null
  );
};

export default SingleProduct;
